let apiEndpoint = location.origin;
let authDomain =  location.origin;
let templateApiEndpoint = location.origin;
if (apiEndpoint.indexOf('localhost') != -1) {
  apiEndpoint = 'https://qa.s.qliktag.com';
  templateApiEndpoint ='https://qa.s.qlkt.ag';
}
export const environment = {
  production: true,
  apiUrl: `${apiEndpoint}/api/`, //=> TEMP CHANGE V1 to V2
  templateApiUrl: `${templateApiEndpoint}/api/`,
  parentOrigin: apiEndpoint,
  googleApiKey: 'AIzaSyB_zT9a1wuMVVyztNePQLscOIW8v5wosgU',
  facebookAppId: '398787767651583',
  facebookShareUrl: 'https://www.facebook.com/dialog/feed',
  twitterShareUrl: 'https://twitter.com/intent/tweet',
  pinterestShareUrl: 'https://www.pinterest.com/pin/create/button/',
  jwt_signature: 'qlikDIseCret',
  authDomain: 'https://auth.s.qlkt.ag',
  firebase: {
    apiKey: "AIzaSyC220turoklBVvGKl1oCA_i_OSAQfw_zA8",
    authDomain: "qliktag-dev.firebaseapp.com",
    projectId: "qliktag-dev",
    storageBucket: "qliktag-dev.appspot.com",
    messagingSenderId: "426886134346",
    appId: "1:426886134346:web:9e90227cd1a85c07b584b3",
    measurementId: "G-RBZ6LWE9FM"
  }
};
